#root {
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
}

a:link {
  color: rgb(25, 51, 73);
  text-decoration: none;
  font-weight: bold;
}

a:visited {
  color: rgb(25, 51, 73);
}

a:hover {
  text-decoration: underline;
}

/* Home */

.home {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
}

.legend {
  z-index: 999;
  position: absolute;
  right: calc(40vw + 10px);
  top: 5px;
  text-align: right;
}

.legend button {
  background-color: white;
  height: 30px;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: grey;
}

.legend button:hover {
  background-color: rgba(255, 255, 255, 1);
  color: black;
  border-color: black;
}

.legend .legend-image {
  background-color: white;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  max-width: calc(60vw - 40px);
}

.legend .legend-image img {
  max-width: 100%;
}

.legend .legend-image.hide {
  display: none;
}

.logo {
  position: fixed;
  left: 10px;
  top: 10px;
  z-index: 999;
}

.logo img {
  width: 35px;
}

.url {
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.url .blue {
  display: inline-block;
  color: #9cdcfe;
}

.url .yellow {
  display: inline-block;
  color: #d7ba7d;
}

.url .orange {
  display: inline-block;
  color: #ce9178;
}

.search {
  z-index: 999;
  position: fixed;
  right: 5px;
  top: 5px;
  width: 40vw;
}

.search input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding-left: 10px;
  box-sizing: border-box;
}

.search input:hover {
  border-color: black;
}

.search img {
  opacity: 0.2;
  position: absolute;
  right: 8px;
  top: 5px;
  height: 20px;
}

.description {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.8);
  max-width: calc(60vw - 20px);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  max-height: 33%;
  overflow-y: auto;
}

.description .title {
  font-weight: bold;
  margin-bottom: 3px;
}

.leaflet-control-layers-expanded.leaflet-control-layers-minimap {
  width: 40vw;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

  padding: 0;
  border: 4px solid rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  overflow-x: hidden;
}
.leaflet-minimap-container {
  margin-bottom: 2px;
  position: relative;
  display: block;
  height: 180px;
}
.leaflet-minimap-container .leaflet-minimap-label {
  position: absolute;
  display: block;

  height: 22px;
  bottom: 0;
  left: 0;
  padding: 2px 6px 2px 2px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  white-space: nowrap;
  z-index: 1000;
}
.leaflet-minimap-container
  .leaflet-minimap-label
  .leaflet-control-layers-selector {
  top: 3px;
}
.leaflet-minimap {
  width: 100%;
  height: 100%;
}
.leaflet-minimap-container.leaflet-minimap-hidden {
  display: none;
}
.leaflet-control-layers-minimap .leaflet-control-layers-scrollbar {
  overflow-y: unset;
  overflow-x: unset;
}

.leaflet-control-container .leaflet-top.leaflet-right {
  margin: 0;
  top: 40px;
}

.leaflet-control-layers.leaflet-control-layers-expanded.leaflet-control-layers-minimap.leaflet-control {
  height: 100%;
  margin: 0;
  background: #ddd;
}
